<template>
  <div class="info_warp">
    <TopCard ref="TopCardRef"
             text="做题详情">
      <template #right>
        <div class="right_card">
          <img src="@/static/doPaper/jiucuo.png"
               @click='press'
               alt=""
               title='反馈'>
          <!-- <img src="@/static/doPaper/naozhong.png"
               alt=""> -->
          <img src="@/static/doPaper/card.png"
               @click="card"
               alt=""
               title='答题卡'>
        </div>
      </template>
    </TopCard>

    <ExportPaper v-if='info.user_paper_id'
                 :id='info.user_paper_id' />
    <div class="paper_id">试卷ID:{{info.user_paper_id}}</div>
    <div class="source_note"
         v-if="info.source_note">备注:{{info.source_note}}</div>
    <div class="content">
      <div class="topic_list">
        <TopicInfo ref="TopicInfoRef"
                   @setAnswer="setAnswer"
                   v-for="(item,idx) in info.questions"
                   :topic="item"
                   :topicIndex="idx"
                   :key="item.question_id" />
        <div class="image_wrap">
          <p class="title">请添加图片:<span>
              （最多可添加6张图片）
            </span>
          </p>
          <div class="img_warp">
            <div class="image"
                 v-for="(item,index) in images"
                 :key="item">
              <a-popconfirm title="确定删除这个图片?"
                            ok-text="确定"
                            cancel-text="取消"
                            @confirm="delteImage(item,index)">
                <img class="close"
                     src="@/static/del.png"
                     alt="">
              </a-popconfirm>

              <a-image :src="addHeader(item)"
                       width="120rem"
                       height="120rem"
                       :key="item">
              </a-image>
            </div>
            <div class="add_img"
                 v-if="images.length  != 6"
                 @click="uploadImage">
              <div>
                <img src="@/static/add_img.png"
                     alt="">
              </div>
              点击上传
            </div>
          </div>
           <Code ref="codeRef" />
        </div>
        <div class="btn_wrap">
          <a-button class="submit_btn"
                    @click="submitPaper">
            提交
          </a-button>
        </div>
      </div>

      <div class="right_scroll">
        <RightScroll ref="rightScrollRef"
                     :topic_numer="topicNumber" />
      </div>
    </div>

    <SubmitDialog ref="submitDialogRef"
                  @sava="sava" />
    <explaindialog ref="explaindialogRef"
                   :dialogVisible="explaindialogVisible"
                   @expalinSubmitDapaper="expalinSubmitDapaper" />
    <backDialog ref="backDialogRef" />
    <pressDialog ref='pressDialogRef' />
  </div>
</template>

<script setup>
import explaindialog from './components/explain.vue'
import backDialog from './components/backDialog.vue'
import TopicInfo from './components/topic.vue'
import { reactive, ref  } from '@vue/reactivity'
import { watch } from 'vue'
import { nextTick, onActivated, onMounted, onUnmounted } from '@vue/runtime-core'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { getKnowledgePaper, getKnowledgeWeakPaper, getTemplatePaper, getTeacherPaper, getWholePaper, getChapterPaperApi, saveAnswer, getCapalilityTrain, continuePaper, submitPaper as submitPaperApi } from "@/api/paper.js"
import { message } from 'ant-design-vue'
import TopCard from '@/views/components/top_card/index.vue'
import RightScroll from '@/views/components/right_card/index.vue'
import { uploadImageAuto } from '@/api/upload.js'
import { addHeader as globalAddHeader } from '@/init/index.js'
import { json } from 'body-parser'
// 使用答题卡那里的弹窗
import SubmitDialog from '@/views/doPaperCard/components/submitPaper.vue'
import pressDialog from './components/topicPressDialog.vue'
import { checkArr as globalCheckArr } from '@/init/index.js'
import ExportPaper from '@/views/components/exportPaper/index.vue'
import Code from '@/views/doPaper/components/code/index.vue'


const route = useRoute()
const router = useRouter()
const explaindialogVisible = ref(false)
const rightScrollRef = ref(null)
const pressDialogRef = ref(null)
const codeRef = ref(null)
let form = reactive({})
let info = reactive({
  questions: []
})
// 存储所有题目的一维数组
let copyTopicList = ref([])
// 题目数，用于右侧滑块
let topicNumber = ref(0)
let images = ref([])
const addHeader = globalAddHeader
let checkArr = globalCheckArr
const submitDialogRef = ref(null)
let isFinish = ref(false) //用于判断退出页面时是提交还是半途退出

onBeforeRouteLeave((to, from, next) => {
  if (to.fullPath != '/testpaper/card' && !isFinish.value) {
    save()
  }
  next()
})

watch(() => info.user_paper_id, () => {
  codeRef.value.creatQrCode(info.user_paper_id)
}, { deep: true })

// onActivated(() => {
//   if (window.sessionStorage.getItem('testpaperCardIndex')) {
//     let paper = JSON.parse(window.sessionStorage.getItem('paper'))
//     images.value = JSON.parse(window.sessionStorage.getItem("images")) || []
//     let topicIndex = Number(window.sessionStorage.getItem('testpaperCardIndex'))
//     if (topicIndex) {
//       scrollToIndex(topicIndex)
//     } else {
//       nextTick(() => {
//         document.getElementsByClassName('content')[0].scrollTop = window.sessionStorage.getItem('scroll_top')
//       })
//     }
//     setInfo(paper)
//     setQuestionAnswerWarp(info.questions)
//   }
// })

// 点击反馈按钮,显示弹窗
const press = () => {
  pressDialogRef.value.dialogVisible = true
}
onMounted(() => {
  isFinish.value = false
  if (Number(window.sessionStorage.getItem('testpaperCardIndex'))) {
    let paper = JSON.parse(window.sessionStorage.getItem('paper'))
    images.value = JSON.parse(window.sessionStorage.getItem("images")) || []
    let topicIndex = Number(window.sessionStorage.getItem('testpaperCardIndex'))
    if (topicIndex) {
      scrollToIndex(topicIndex)
    } else {
      nextTick(() => {
        document.getElementsByClassName('content')[0].scrollTop = window.sessionStorage.getItem('scroll_top')
      })
    }
    setInfo(paper)
    topicNumber.value = 0
    setQuestionAnswerWarp(info.questions)
  }
  //  else if (route.query.isTeacherPaper) {
  //   // 处理从教师试卷过来的逻辑
  //   doTeacherPaper(JSON.parse(route.query.form))
  // }
  // 处理知识点/教师试卷过来的逻辑
  else if (route.query.isKnowledge || route.query.isChapter || route.query.isTeacherPaper || route.query.isKnowledgeWeak || route.query.isTemplate) {
    explaindialogVisible.value = true
  }
  else if (route.query.user_paper_id) {

    // 处理继续做题的情况
    if (route.query.isFrreeDom) {
      // 还有一种情况是从自由选题跳转过来的情况，拿了user_paper_id 在这里获取,因为是重新做的一套卷子  需要提示相应的注意事项
      explaindialogVisible.value = true
    }
    goOnPaper()
  } else {
    // 正常从试卷列表页面进来的情况
    window.sessionStorage.removeItem('testpaperCardIndex')
    window.sessionStorage.removeItem('paper')
    let queryForm = JSON.parse(route.query.form)
    for (const key in queryForm) {
      form[key] = queryForm[key]
    }
    window.sessionStorage.setItem('testpaperCardIndex', 0)
    explaindialogVisible.value = true
  }
})

const setInfo = (paper) => {
  for (const key in paper) {
    info[key] = paper[key]
  }
  nextTick(() => {
    rightScrollRef.value.mountScrollEvent()
  })
  window.sessionStorage.setItem('user_paper_id', info.user_paper_id)
}

const setQuestionAnswerWarp = (arr) => {

  for (var index = 0; index < arr.length; index++) {
    arr[index].topic = String(arr[index].topic)
    if (arr[index].question_children) {
      arr[index].question_children = setQuestionAnswerWarp(arr[index].question_children)
    } else {
      topicNumber.value += 1
      if (arr[index].user_answer) {
        arr[index].answer = arr[index].user_answer.split(',')
      } else if (!(arr[index].answer instanceof Array)) {
        arr[index].answer = []
      }
      copyTopicList.value.push(arr[index])
    }

  }
  return arr
}

const setAnswer = (answer, index, topicIndex) => {
  let paperTest = info.questions[topicIndex]
  // 如果是多子题的话，需要index定位该题的位置
  if (paperTest.question_children && paperTest.question_children.length) {
    paperTest = paperTest.question_children[index]
  }
  /*
    在这里只会是小题的情况
  */
  //多选的情况下，再次点击，只会取消选择
  if (paperTest.answer.indexOf(answer) != -1) {
    paperTest.answer.splice(paperTest.answer.indexOf(answer), 1)
  } else if (paperTest.topic == '1') {
    //单选的情况
    paperTest.answer = [answer]
    //多选和不定项选，全部归为多选
  } else if (paperTest.topic == '2' || paperTest.topic == '3' || paperTest.topic == '4') {
    //双选
    if (paperTest.answer.length == 2 && paperTest.topic == '4') {
      return
    }
    paperTest.answer.push(answer)
  } else if (['9', '13', '14', '15'].indexOf(paperTest.topic) != -1) {
    paperTest.answer = [answer]
  }
}

const getAnswer = (topicList) => {
  var arr = []
  topicList.forEach(item => {
    if (item.answer.length != 0) {
      let answerStr = item.answer instanceof Array ? item.answer.join(',') : item.answer
      arr.push({ answer: answerStr, question_id: item.question_id })
    }
  })
  return arr
}

// 知识点组卷获取试卷
const getKnowledge = async () => {
  const { data } = await getKnowledgePaper(JSON.parse(route.query.form))
  topicNumber.value = 0
  data.list.questions = setQuestionAnswerWarp(data.list.questions)

  setInfo(data.list)
}

// 知识点弱点获取试卷
const getKnowledgeWeak = async () => {
  const { data } = await getKnowledgeWeakPaper(JSON.parse(route.query.form))
  topicNumber.value = 0
  data.list.questions = setQuestionAnswerWarp(data.list.questions)
  setInfo(data.list)
}
//能力素养组卷
const getCapWeak = async () => {
  const { data } = await getCapalilityTrain(JSON.parse(route.query.form))
  topicNumber.value = 0
  data.list.questions = setQuestionAnswerWarp(data.list.questions)
  setInfo(data.list)
}
const getTemplate = async () => {
  const { data } = await getTemplatePaper(JSON.parse(route.query.form))
  topicNumber.value = 0
  data.list.questions = setQuestionAnswerWarp(data.list.questions)
  setInfo(data.list)

}

// 教师试卷
const doTeacherPaper = async (params) => {
  const { data } = await getTeacherPaper(params)
  topicNumber.value = 0

  data.list.questions = setQuestionAnswerWarp(data.list.questions)
  setInfo(data.list)
}

// 整卷做卷-模板做卷 getWholePaper
const getPaperInfo = async () => {
  const { data } = await getWholePaper(form)
  topicNumber.value = 0

  data.list.questions = setQuestionAnswerWarp(data.list.questions)
  setInfo(data.list)
}

// 章节组卷
const getChapterPaper = async () => {
  const { data } = await getChapterPaperApi({ chapter_id: route.query.chapter_id })
  topicNumber.value = 0

  data.list.questions = setQuestionAnswerWarp(data.list.questions)
  setInfo(data.list)
}

// 保存,做到一半退出页面-需保存答案
const save = async () => {
  if (!window.sessionStorage.getItem('user_paper_id')) {
    message.error("数据丢失")
    return
  }
  let data = {
    user_paper_id: window.sessionStorage.getItem('user_paper_id'),
    other_answer: images.value || JSON.parse(window.sessionStorage.getItem('images')) || [],
    user_answer: JSON.stringify(getAnswer(copyTopicList.value))
  }
  if (data.user_answer.length == '[]') {
    return
  }
  await saveAnswer(data)
}

// 点击答题卡
const card = (index) => {
  window.sessionStorage.setItem('paper', JSON.stringify(info))
  window.sessionStorage.setItem('testpaperCardIndex', 1)
  window.sessionStorage.setItem('images', JSON.stringify(images.value))

  var oapp = document.getElementsByClassName('content')[0]
  window.sessionStorage.setItem('scroll_top', oapp.scrollTop)

  router.push('/testpaper/card')
}

// 继续做卷
const goOnPaper = async () => {
  const { data } = await continuePaper({ user_paper_id: route.query.user_paper_id })
  topicNumber.value = 0

  data.list.questions = setQuestionAnswerWarp(data.list.questions)
  setInfo(data.list)
  window.sessionStorage.setItem('images', JSON.stringify(info.other_answer || []))
}

// explain组件点击开始答题触发
const expalinSubmitDapaper = () => {
  if (route.query.isKnowledge) {
    getKnowledge()
  } else if (route.query.isKnowledgeWeak) {
    // 弱点训练
    getKnowledgeWeak()
  }
  else if (route.query.isCapability) {
    getCapWeak()
  }
  else if (route.query.isTemplate) {
    getTemplate()
  } else if (route.query.isTeacherPaper && route.query.form) {
    // 处理从教师试卷过来的逻辑
    doTeacherPaper(JSON.parse(route.query.form))
  } else if (route.query.form) {
    // console.log(789)
    getPaperInfo()
  } else if (route.query.isChapter) {
    getChapterPaper()
  } 
  explaindialogVisible.value = false
}

const uploadImage = async () => {
  const { data } = await uploadImageAuto(20, "image/*")
  images.value.push(data.dir)
  message.success('上传成功')
}

// 点击删除图片
const delteImage = (item, index) => {
  console.log(index, "让用户确认删除这个图片")
  images.value.splice(index, 1)
}

const scrollToIndex = (index) => {
  nextTick(() => {
    // 调用scroll方法就行
    rightScrollRef.value.toMaodian(index)
  })
}

// 提交试卷
const submitPaper = () => {
  let number = 0
  copyTopicList.value.forEach(item => {
    if (item.answer.length == 0 && checkArr.indexOf(item.topic) != -1) {
      number += 1
    } else if (images.value.length == 0 && checkArr.indexOf(item.topic) == -1) {
      number += 1
    }
  })
  submitDialogRef.value.setData(number, true)
}

const getAnswerUseCard = (topicList) => {
  var arr = {}
  topicList.forEach(item => {
    if (item.answer.length != 0) {
      let answerStr = item.answer instanceof Array ? item.answer.join(',') : item.answer
      arr[item.answer_id] = answerStr
    }
  })
  return arr
}

const sava = async () => {

  let params = {
    user_paper_id: info.user_paper_id,
    other_answer: JSON.stringify(images.value),
    chs_answer: getAnswerUseCard(copyTopicList.value)
  }
  await submitPaperApi(params)
  isFinish.value = true
  router.go(-1)
  message.success("提交成功")
  window.sessionStorage.removeItem('images')
  window.sessionStorage.removeItem('paper')
}

</script>

<style lang="scss" scoped>
.info_warp {
  width: 100vw;
  background: #f6f6f6;
  .paper_id {
    padding: 10rem 30rem;
    font-size: 20rem;
    font-weight: 700;
  }
  .content {
    flex: 1;
    display: flex;
    height: calc(100vh - 208rem);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40rem;
  }
  .right_scroll {
    flex-shrink: 0;
    width: 100rem;
    margin-left: 20rem;
  }
}

.image_wrap {
  background: #ffffff;
  margin-left: 20rem;
  margin-top: 20rem;
  padding: 30rem;
  box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
  .title {
    font-size: 20rem;
    font-weight: bold;
    color: #333333;
    span {
      color: #848484;
    }
  }
  .add_img {
    width: 120rem;
    height: 120rem;
    background: #f6f6f6;
    border-radius: 10rem;
    font-size: 20rem;
    font-weight: 500;
    color: #2196f3;
    text-align: center;
    cursor: pointer;
    img {
      width: 40rem;
      height: 34rem;
      margin-top: 26rem;
      margin-bottom: 6rem;
    }
  }
}
.right_card {
  margin-right: 70rem;
  img {
    width: 36rem;
    height: 36rem;
    margin-right: 30rem;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}

.img_warp {
  display: flex;
  margin-left: 20px;
  margin-top: 19px;
  .image {
    position: relative;
    width: 120rem;
    height: 120rem;
    margin-right: 24rem;
    justify-content: center;
    .close {
      position: absolute;
      right: -5px;
      top: -5px;
      background: red;
      color: white;
      width: 30rem;
      height: 30rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8px;
      z-index: 19;
    }
  }
  .add_img {
    width: 120rem;
    height: 120rem;
    font-size: 20rem;
    font-weight: 500;
    background: #f6f6f6;
    border-radius: 10rem;
    text-align: center;
    color: #2196f3;
    cursor: pointer;
    img {
      width: 40rem;
      height: 34rem;
      margin-top: 26rem;
      margin-bottom: 14rem;
    }
  }
}
.btn_wrap {
  height: 120rem;
  .submit_btn {
    width: calc(100% - 20rem);
    margin-left: 20rem;
    margin-top: 60rem;
    height: 60rem;
    background: #2196f3;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24rem;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }
}
.source_note {
  padding: 5rem 30rem;
  font-size: 20rem;
  margin-top: -10rem;
  margin-bottom: 20rem;
}
</style>